import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import React from 'react';

const ServerSideTable = ({ columns, data, hideHeader=false }) => {
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        initialState: {
            pageSize: 10,
            columnVisibility: {
                firstName: true,
                lastName: true,
                age: true,
                visits: true,
                status: true,
                progress: true,
                id: false
            },
            expanded: true
        }
    });

    return (
        <div className="overflow-x-auto rounded-t-xl">
            <table className="min-w-full divide-y divide-gray-200">
                {!hideHeader && (
                <thead className="bg-gray-50">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`} style={{ width: '150px' }}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                )}
                <tbody className="bg-white divide-y divide-gray-200">
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className="px-6 py-4" style={{ width: '150px' }}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ServerSideTable;