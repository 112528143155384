import React from 'react';
import {DashboardCounter} from './dashboard-counter';
import { CommentDocumentSVG } from './CommentDocumentSVG';




export function CommentCounter(activityType) {
    const getURL = `/tips/?current_user_only=true`;
    const queryKeyArray = ["tips", "current_user"];
    const name = "Tips";
    const icon = <CommentDocumentSVG classes={"h-8 w-8"}/>;

    return (
        <>
            <DashboardCounter
                icon={icon}
                getURL={getURL}
                queryKeyArray={queryKeyArray}
                name={name}
            />
        </>
    );
}
