import React, {Fragment, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'


export default function TextAreaModalHTTPPost(props) {
    const cancelButtonRef = useRef(null)
    const inputRef = useRef(null);

    const onInputChanged = (e) => {
        props.setTextArea(e.target.value)
    }

    const handleKeyPress = (event) => {
        console.log(event.key)

        if (event.key === 'Enter') {
            props.submitAction();
        }
    };

    const onSubmitClicked = () => {
        props.submitAction();
        props.setIsVisible(false);
    }

    return (
        <Transition.Root show={props.isVisible} as={Fragment}>
            <Dialog as="div" className="relative z-20" initialFocus={cancelButtonRef} onClose={() => {props.setIsVisible(false)}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-20 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                            <input readOnly={true} checked={true} type="checkbox" id="my-modal" className="modal-toggle" />
                            <div onKeyDown={handleKeyPress} className="modal">
                                <div className="modal-box max-w-2xl h-80">
                                    {/*<CloseToLastPage/>*/}
                                    <h3 className="font-bold text-xl capitalize">{props.title}</h3>
                                    <textarea value={props.textarea || "" } ref={inputRef} type="text" onChange={onInputChanged} placeholder="Type here"
                                              className="text-md h-36 border-b-gray-400 mt-4 w-full max-w-3xl overflow-y-scroll" />
                                    <div className={"flex space-x-2 justify-end"}>
                                        <span className="modal-action">
                                            <button onClick={() => {props.setIsVisible(false)}} htmlFor="my-modal" className="btn">Cancel</button>
                                        </span>
                                        <span className="modal-action">
                                            <button onClick={onSubmitClicked} htmlFor="my-modal"
                                                   className="btn">Submit</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

TextAreaModalHTTPPost.defaultProps = {
    title: "Title",
    submitAction: () => {},
    textarea: "",
    setTextArea: () => {},
    isVisible: false,
    setIsVisible: () => {}

}
