import React from 'react';
import "../map.css"
import {isBot} from "../is-bot";


// import mapboxgl from 'mapbox-gl';
import {Popup} from "./popup";
import {MapContext} from "../../contexts/map-context";
import {
    CAMPSITE_LAYER,
    CAMPSITE_LAYER_ID,
    CLIMBING_LAYER,
    CLIMBING_LAYER_ID,
    SELECTED_TRAIL_COLOR,
    TRAIL_COLOR,
    TRAIL_LAYER,
    TRAIL_LAYER_ID,
    MAP_TOKEN,
    MAP_STYLE_OUTDOORS,
    CLIMBING_COLOR,
    SELECTED_CLIMBING_COLOR,
    CAMPSITE_COLOR,
    SELECTED_CAMPSITE_COLOR,
    COMBINED_SOURCE, COMBINED_SOURCE_ID
} from "./map-constants";
import { MapboxExportControl, Size } from "@watergis/mapbox-gl-export";
import '@watergis/mapbox-gl-export/css/styles.css';
import {ThreeDMapControl} from "./map-controls";
import {DEFAULT_LONGITUDE, DEFAULT_LATITUDE, DEFAULT_ZOOM} from "./location-utls";

const MAP_IMAGE_URL_NAME = 'map-thumbnail'

const mapboxgl = window.mapboxgl;
mapboxgl.AccessToken = MAP_TOKEN;


/******************************************************************************
 * BaseMap
 *****************************************************************************/
class BaseMap extends React.Component {

    static contextType = MapContext;


    constructor() {
        super();

        // the word map-thumbnail is in the url set preserverDrawingBuffer to true
        this.preserveDrawingBuffer = window.location.href.includes(MAP_IMAGE_URL_NAME) ? true : false

        this.isBot = isBot
        console.log("IS BOT", this.isBot)
        this.mapContainer = React.createRef();
        this.map = React.createRef();
        this.state = {
            popupVisible: false,
            popupActivityType: null,
            popupActivityName: null,
        }

        // allows child components to be able to disable controls for 3d, print, etc.
        this.shouldAddControls = true

        // allows child components to be able to style depending on the activity or whether map is full page
        this.mapStyle = "map-container rounded-3xl shadow-xl"

        // allows child components to be able to set the map zoom and center
        this.mapZoom = DEFAULT_ZOOM;
        this.center = [DEFAULT_LONGITUDE, DEFAULT_LATITUDE]

        // bind hoveron and hoveroff to this
        this.hoveron = this.onMouseEnterAll.bind(this);
        this.hoveroff = this.onMouseLeaveAll.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onClickCampsite = this.onClickCampsite.bind(this);
        this.onClickTrail = this.onClickTrail.bind(this);
        this.onClickClimbing = this.onClickClimbing.bind(this);
        this.addLayers = this.addLayers.bind(this);
        this.onMouseEnterAll = this.onMouseEnterAll.bind(this);
        this.onMouseLeaveAll = this.onMouseLeaveAll.bind(this);
        this.onMouseEnterTrail = this.onMouseEnterTrail.bind(this);
        this.onMouseLeaveTrail = this.onMouseLeaveTrail.bind(this);
        this.onMouseEnterClimbing = this.onMouseEnterClimbing.bind(this);
        this.onMouseLeaveClimbing = this.onMouseLeaveClimbing.bind(this);
        this.onMouseEnterCampsite = this.onMouseEnterCampsite.bind(this);
        this.onMouseLeaveCampsite = this.onMouseLeaveCampsite.bind(this);
        this.addControls = this.addControls.bind(this);
        this.screenshotMap = this.screenshotMap.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.isBot) {
            return
        }

        // check if layer visibility has changed by comparing the context to the maps current layout property
        if (this.context.campingToggleLayerVisible !== this.map.current.getLayoutProperty('campsites', 'visibility') ||
            this.context.trailToggleLayerVisible !== this.map.current.getLayoutProperty('trails', 'visibility') ||
            this.context.climbingToggleLayerVisible !== this.map.current.getLayoutProperty('climbing', 'visibility')) {
            this.map.current.setLayoutProperty('campsites', 'visibility', this.context.isVisibility(this.context.campingToggleLayerVisible));
            this.map.current.setLayoutProperty('trails', 'visibility', this.context.isVisibility(this.context.trailToggleLayerVisible));
            this.map.current.setLayoutProperty('climbing', 'visibility', this.context.isVisibility(this.context.climbingToggleLayerVisible));
        }

        if (this.map.current.getStyle().sprite.search(this.context.mapBaseStyle) === -1) {
            console.log("changing style")
            this.map.current.setStyle("mapbox://styles/mapbox/" + this.context.mapBaseStyle)
        }
    }

    componentDidMount() {
        if (this.isBot) {
            console.log("BOT")
            return
        }

        console.log("COMPONENT DID MOUNT")
        if (this.map) {
            console.log("CREATING MAP")
            this.map.current = new mapboxgl.Map({
                container: this.mapContainer.current,
                style: MAP_STYLE_OUTDOORS + this.context.mapBaseStyle,
                accessToken: MAP_TOKEN,
                center: this.center,
                zoom: this.mapZoom,
                preserveDrawingBuffer: this.preserveDrawingBuffer,
                transformRequest: (url, resourceType) => {
                    // Construct headers dynamically
                    let headers =  {}

                    if (resourceType === 'Tile' && url.includes('user-')) {
                        headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
                        return {
                            url: url,
                            headers: headers,
                        };
                    }
                }
            });
            this.map.current.on('load', this.addControls)
            this.map.current.on('style.load', this.onLoad)
            this.screenshotMap()

        }
    }

    componentWillUnmount() {
        if (this.isBot) {
            return
        }

        console.log("UNMOUNTING MAP")

        this.map.current.off('mouseenter', [CAMPSITE_LAYER_ID, TRAIL_LAYER_ID, CLIMBING_LAYER_ID], this.onMouseEnterAll);
        this.map.current.off('mouseleave', [CAMPSITE_LAYER_ID, TRAIL_LAYER_ID, CLIMBING_LAYER_ID], this.onMouseLeaveAll);

        this.map.current.off('click', TRAIL_LAYER_ID, this.onClickTrail);
        this.map.current.off('click', CLIMBING_LAYER_ID, this.onClickClimbing);
        this.map.current.off('click', CAMPSITE_LAYER_ID, this.onClickCampsite);

        this.map.current.off('mouseenter', TRAIL_LAYER_ID, this.onMouseEnterTrail);
        this.map.current.off('mouseleave', TRAIL_LAYER_ID, this.onMouseLeaveTrail);

        this.map.current.off('mouseenter', CLIMBING_LAYER_ID, this.onMouseEnterClimbing);
        this.map.current.off('mouseleave', CLIMBING_LAYER_ID, this.onMouseLeaveClimbing);

        this.map.current.off('mouseenter', CAMPSITE_LAYER_ID, this.onMouseEnterCampsite);
        this.map.current.off('mouseleave', CAMPSITE_LAYER_ID, this.onMouseLeaveCampsite);

        this.map.current.remove();

    }



    render() {
        return (
            this.isBot ?
                <>
                    <div className={'rounded-3xl bg-slate-100 z-1000 justify-center h-full align-middle text-center text-9xl'}>Map</div>
                </> :
                <>
                    <Popup popupVisible={this.state?.popupVisible}
                           activityType={this.state?.popupActivityType}
                           activityName={this.state?.popupActivityName}
                    />
                    <div ref={this.mapContainer} className={this.mapStyle} />
                </>
        );
    }
}


function setCenter() {
    console.log("parent set center")
}

/******************************************************************************
    * Screenshot the map
 * ***************************************************************************/
BaseMap.prototype.screenshotMap = function() {
    // this will let us create a png that we can use as an thumbnail, automation will set the url to include thumbnail
    if (window.location.href.includes(MAP_IMAGE_URL_NAME)) {
        this.map.current.once('idle', () => {
            console.log("print image")
            const PICTURE_QUALITY = .5
            var img  = this.map.current.getCanvas().toDataURL("image/webp", PICTURE_QUALITY);
            console.log("IMG", img)
            // get the slug from the url
            const activity = window.location.href.split("/")[3]
            const slug = window.location.href.split("/")[4]
            console.log("slug", slug)

            const filename = `${activity}-${slug}.webp`; // Desired filename for the download
            downloadBase64Image(img, filename);
        })
    }
}


function downloadBase64Image(base64Image, filename) {
    // Convert base64 to blob
    const byteCharacters = atob(base64Image.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: 'image/webp'}); // Adjust the type based on your image base64 string

    // Create a URL for the blob
    const imageUrl = URL.createObjectURL(blob);

    // Create an anchor (<a>) element to trigger download
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = filename; // Set the filename for the download

    // Append the anchor to the document body temporarily to trigger download
    document.body.appendChild(downloadLink);
    downloadLink.click(); // Trigger the download

    // Clean up by removing the anchor element and revoking the blob URL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(imageUrl);
}

/******************************************************************************
 * LOAD Map Listeners
******************************************************************************/
BaseMap.prototype.onLoad = function(e) {
    console.log("LOADING MAP LISTENERS")
    console.log("map style", this.map.current.getStyle())
    this.addLayers();

    this.map.current.on('click', TRAIL_LAYER_ID, this.onClickTrail);
    this.map.current.on('click', CLIMBING_LAYER_ID, this.onClickClimbing);
    this.map.current.on('click', CAMPSITE_LAYER_ID, this.onClickCampsite);

    this.map.current.on('mouseenter', [CAMPSITE_LAYER_ID, TRAIL_LAYER_ID, CLIMBING_LAYER_ID], this.onMouseEnterAll);
    this.map.current.on('mouseleave', [CAMPSITE_LAYER_ID, TRAIL_LAYER_ID, CLIMBING_LAYER_ID], this.onMouseLeaveAll);

    this.map.current.on('mouseenter', TRAIL_LAYER_ID, this.onMouseEnterTrail);
    this.map.current.on('mouseleave', TRAIL_LAYER_ID, this.onMouseLeaveTrail);

    this.map.current.on('mouseenter', CLIMBING_LAYER_ID, this.onMouseEnterClimbing);
    this.map.current.on('mouseleave', CLIMBING_LAYER_ID, this.onMouseLeaveClimbing);

    this.map.current.on('mouseenter', CAMPSITE_LAYER_ID, this.onMouseEnterCampsite);
    this.map.current.on('mouseleave', CAMPSITE_LAYER_ID, this.onMouseLeaveCampsite);

}


/******************************************************************************
 * click on a trail should redirect to the trail page using the slug
 *****************************************************************************/
BaseMap.prototype.onClickTrail = function(e) {

    const slug = e.features[0].properties.slug;
    this.props.navigation('/trails/' + slug + '/')
}

/******************************************************************************
 * click on a climbing should redirect to the climbing page using the slug
 *****************************************************************************/
BaseMap.prototype.onClickClimbing = function(e) {
    // Copy coordinates array.
    const slug = e.features[0].properties.slug;
    this.props.navigation('/climbing/' + slug + '/');
}

/******************************************************************************
* click on a campsite should redirect to the campsite page using the slug
******************************************************************************/
BaseMap.prototype.onClickCampsite = function(e) {
    // Copy coordinates array.
    const slug = e.features[0].properties.slug;
    this.props.navigation('/campsites/' + slug + '/');
}

/******************************************************************************
 * Add the layers to the map
*****************************************************************************/
BaseMap.prototype.addLayers = function() {


    const storedDatetimeString = localStorage.getItem('tileStamp');
    let tileParams = null;

    // console.log("caches", this.map.current.style)

    // const sourceCache = this.map.current.style._sourceCaches["other:composite"];
    // for (const id in sourceCache._tiles) {
    //     sourceCache._tiles[id].expirationTime = Date.now() - 1;
    //     sourceCache._reloadTile(id, 'reloading');
    // }
    // sourceCache._cache.reset();
    // this.map.current.triggerRepaint();
    // tileParams = new Date().toISOString()

    if (storedDatetimeString) {
        const storedDatetime = new Date(storedDatetimeString);
        const currentDatetime = new Date();
        const twentyMinsInMillis = 20 * 60 * 1000;  // 20 mins in milliseconds


        if (currentDatetime - storedDatetime < twentyMinsInMillis) {
            console.log("within window");
            tileParams = new Date().toISOString()

            console.log("caches", this.map.current.style.sourceCaches)
            // const sourceCache = this.map.current.style.sourceCaches[COMBINED_SOURCE];
            // for (const id in sourceCache._tiles) {
            //     sourceCache._tiles[id].expirationTime = Date.now() - 1;
            //     sourceCache._reloadTile(id, 'reloading');
            // }
            // sourceCache._cache.reset();
            // this.map.current.triggerRepaint();
        } else {
            console.log("outside of window");
            localStorage.removeItem('tileStamp');
        }
    } else {
        console.log("tileStamp not found in local storage");
    }


    if (tileParams) {
        COMBINED_SOURCE.tiles = [ COMBINED_SOURCE.tiles[0] + '/?tileStamp=' + tileParams ]
    }
    // these enable 3d maps, 'dem' refers to the digital elevation model
    this.map.current.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb',
        'tileSize': 512,
        'maxzoom': 20
    });
    this.map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 0 });

    this.map.current.addSource(COMBINED_SOURCE_ID, COMBINED_SOURCE);

    this.map.current.addLayer(TRAIL_LAYER);
    this.map.current.addLayer(CAMPSITE_LAYER);
    this.map.current.addLayer(CLIMBING_LAYER);

    // set visibility of layers
    this.map.current.setLayoutProperty('campsites', 'visibility', this.context.isVisibility(this.context.campingToggleLayerVisible));
    this.map.current.setLayoutProperty('trails', 'visibility', this.context.isVisibility(this.context.trailToggleLayerVisible));
    this.map.current.setLayoutProperty('climbing', 'visibility', this.context.isVisibility(this.context.climbingToggleLayerVisible));

}

/******************************************************************************
 * Add the controls to the map
 *****************************************************************************/
BaseMap.prototype.addControls = function() {

    // allow base classes to disable controls
    if (this.shouldAddControls === false) {
        return
    }


    // navigation
    const nav = new mapboxgl.NavigationControl({
        showZoom: false
    })
    this.map.current.addControl(nav);

    //export
    this.map.current.addControl(new MapboxExportControl({
        PageSize: Size.A4,
        PrintableArea: true,
        accessToken: MAP_TOKEN,
    }), 'top-right');


    // 3d
    const threeD = new ThreeDMapControl()
    this.map.current.addControl(threeD, 'top-right');

    // fullscreen
    this.map.current.addControl(new mapboxgl.FullscreenControl());

    this.map.current.addControl(new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'imperial'
    }));

}

/******************************************************************************
 * mouse enter all activities should show a popup
******************************************************************************/
BaseMap.prototype.onMouseEnterAll = function(e) {
    e.target.getCanvas().style.cursor = 'pointer';
    const features = e.target.queryRenderedFeatures(e.point);
    if (features.length) {
        const feature = features[0];
        const layerId = feature.layer.id;

        // console.log("ID", feature)

        if (feature.properties.name) {
            this.setState(prevState => ({ popupVisible: true,
                                           popupActivityType: layerId,
                                           popupActivityName: feature.properties.name }));
        }

    }
};

/******************************************************************************
 * mouse leave all activities should hide the popup
 ***************************************************************************/
BaseMap.prototype.onMouseLeaveAll = function(e) {
    e.target.getCanvas().style.cursor = '';
    this.setState(prevState => ({ popupVisible: false }));
    this.setState(prevState => ({ popupActivityName: "" }));
}

/******************************************************************************
* mouse enter a trail should change the color to the selected color
******************************************************************************/
BaseMap.prototype.onMouseEnterTrail = function(e) {

    // change the feature to a different color
    this.map.current.setPaintProperty(TRAIL_LAYER_ID, 'line-color', [
        'match',
        ['get', 'slug'],
        e.features[0].properties.slug,
        SELECTED_TRAIL_COLOR,
        TRAIL_COLOR
    ]);

    // change the feature to a differnt line width
    this.map.current.setPaintProperty(TRAIL_LAYER_ID, 'line-width', [
        'match',
        ['get', 'slug'],
        e.features[0].properties.slug,
        5,
        3
    ]);
}

/******************************************************************************
* mouse enter a climb should change the color to the selected color
******************************************************************************/
BaseMap.prototype.onMouseEnterClimbing = function(e) {

    // change the feature to a different color
    this.map.current.setPaintProperty(CLIMBING_LAYER_ID, 'circle-color', [
        'match',
        ['get', 'slug'],
        e.features[0].properties.slug,
        SELECTED_CLIMBING_COLOR,
        CLIMBING_COLOR
    ]);
}

/******************************************************************************
* mouse enter a campsite should change the color to the selected color
***************************************************************************/
BaseMap.prototype.onMouseEnterCampsite = function(e) {

    // change the feature to a different color
    this.map.current.setPaintProperty(CAMPSITE_LAYER_ID, 'circle-color', [
        'match',
        ['get', 'slug'],
        e.features[0].properties.slug,
        SELECTED_CAMPSITE_COLOR,
        CAMPSITE_COLOR
    ]);

}

/******************************************************************************
* mouse leave a trail should change the color back
***************************************************************************/
BaseMap.prototype.onMouseLeaveTrail = function(e) {
    // change the feature to a different color
    this.map.current.setPaintProperty(TRAIL_LAYER_ID, 'line-color', [
        'match',
        ['get', 'slug'],
        '',
        SELECTED_TRAIL_COLOR,
        TRAIL_COLOR
    ]);
    
    // change the line width back to normal
    this.map.current.setPaintProperty(TRAIL_LAYER_ID, 'line-width', [
        'match',
        ['get', 'slug'],
        '',
        3,
        3
    ]);

}

/******************************************************************************
* mouse leave a climb should change the color back
******************************************************************************/
BaseMap.prototype.onMouseLeaveClimbing = function(e) {

    // change the feature to a different color
    this.map.current.setPaintProperty(CLIMBING_LAYER_ID, 'circle-color', [
        'match',
        ['get', 'slug'],
        '',
        SELECTED_CLIMBING_COLOR,
        CLIMBING_COLOR
    ]);

}

/******************************************************************************
* mouse leave a campsite should change the color back
******************************************************************************/
BaseMap.prototype.onMouseLeaveCampsite = function(e) {

    // change the feature to a different color
    this.map.current.setPaintProperty(CAMPSITE_LAYER_ID, 'circle-color', [
        'match',
        ['get', 'slug'],
        '',
        SELECTED_CAMPSITE_COLOR,
        CAMPSITE_COLOR
    ]);

}

export default BaseMap;