import React from "react";
import withRouter from "../../hooks/withRouter";
import BaseMap from "./base-map";
import {CURRENT_CLIMBING_LAYER_ID, CURRENT_CLIMBING_SOURCE_ID, SELECTED_CLIMBING_COLOR} from "./map-constants";
import {getLocationArray} from "./location-utls";



/******************************************************************************
 * DashboardBaseMap
 *****************************************************************************/
class DashboardBaseMap extends BaseMap {

    constructor(props) {
        super();

        this.onClick = this.onClick.bind(this);
        this.addLayerAndSource = this.addLayerAndSource.bind(this);
    }


    componentWillUnmount() {
        super.componentWillUnmount();
    
        this.map.current.off('mouseenter', ['user-favorites'], this.onMouseEnterAll);
        this.map.current.off('mouseleave', ['user-favorites'], this.onMouseLeaveAll);
        this.map.current.off('click', 'user-favorites', this.onClick);
        this.map.current.off('click', 'user-reviews', this.onClick);

    }


    // add layer and source that accepts image name, source, source layer, and id
    addLayerAndSource(fileName, imageName, sourceName, sourceLayerName, sourceLayerRouteName, idName, size=1) {
        this.map.current.loadImage(
            fileName,
            (error, image) => {
                if (error) throw error;
                this.map.current.addImage(imageName, image);
                // Add a GeoJSON source with 2 points

                this.map.current.addSource(sourceName, {
                    'type': 'vector',
                    'tiles': [process.env.REACT_APP_WEBSVC_URL + '/tiles/' + sourceLayerRouteName + '/{z}/{x}/{y}/'],  // URL to your MVT endpoint
                    'minzoom': 0,
                    'maxzoom': 18,
                });

                this.map.current.addLayer({
                    'id': idName,  // A unique ID for this layer
                    'type': 'symbol',  // Change to 'symbol' for using icon images
                    'source': sourceName,
                    'source-layer': sourceLayerName,  // This should match the layer name in your MVT
                    'layout': {
                        'icon-image': imageName,
                        'icon-size': size,  // Adjust size as needed (values typically range between 0.5 and 2)
                        'icon-offset': [30, 30],
                    },
                    'paint': {
                        // Optional icon color styling if using SDF (make sure the image was added with { sdf: true })
                        'icon-color': '#FF0000'
                    }
                });

                this.map.current.on('mouseenter', [idName], this.onMouseEnterAll);
                this.map.current.on('mouseleave', [idName], this.onMouseLeaveAll);

                this.map.current.on('click', idName, this.onClick);

            }
        );
    }



    componentDidMount(prevProps, prevState, snapshot) {
        if (this.isBot) {
            return
        }
        super.componentDidMount();

        getLocationArray()
        .then((coordinates) => {

                if (coordinates.hasApprovedPermissions) {
                    this.map.current.setCenter([coordinates.lon, coordinates.lat]);
                    this.map.current.setZoom(coordinates.zoom);

                }
                else {
                    this.map.current.flyTo({
                        center: [coordinates.lon, coordinates.lat],
                        zoom: coordinates.zoom,
                    });
                }
            }
        );

        console.log("Dashboard MAP", this.map.current)





        this.map.current.on('load', () => {
            // Ensure svgUrl is a Base64 data URL


            this.addLayerAndSource('/checkmark.png', 'beenthere-marker', 'user-beenthere-source', 'user_been_there', 'user-been-there' , 'user-beenthere', .2);
            //
            // this.map.current.loadImage(
            //     '/checkmark.png',
            //     (error, image) => {
            //         if (error) throw error;
            //         this.map.current.addImage('beenthere-marker', image);
            //         // Add a GeoJSON source with 2 points
            //
            //         this.map.current.addSource('user-beenthere-source', {
            //             'type': 'vector',
            //             'tiles': [process.env.REACT_APP_WEBSVC_URL + '/tiles/user-been-there/{z}/{x}/{y}/'],  // URL to your MVT endpoint
            //             'minzoom': 0,
            //             'maxzoom': 18,
            //         });
            //
            //         this.map.current.addLayer({
            //             'id': 'user-beenthere',  // A unique ID for this layer
            //             'type': 'symbol',  // Change to 'symbol' for using icon images
            //             'source': 'user-beenthere-source',
            //             'source-layer': 'user_been_there',  // This should match the layer name in your MVT
            //             'layout': {
            //                 'icon-image': 'beenthere-marker',
            //                 'icon-size': .2,  // Adjust size as needed (values typically range between 0.5 and 2)
            //                 'icon-offset': [30, 30],
            //             },
            //             'paint': {
            //                 // Optional icon color styling if using SDF (make sure the image was added with { sdf: true })
            //                 'icon-color': '#FF0000'
            //             }
            //         });
            //
            //         this.map.current.on('mouseenter', ['user-beenthere'], this.onMouseEnterAll);
            //         this.map.current.on('mouseleave', ['user-beenthere'], this.onMouseLeaveAll);
            //
            //         this.map.current.on('click', 'user-beenthere', this.onClick);
            //
            //     }
            // );


            this.map.current.loadImage(
                '/camera.png',
                (error, image) => {
                    if (error) throw error;
                    this.map.current.addImage('photo-marker', image);
                    // Add a GeoJSON source with 2 points
                    
                    this.map.current.addSource('user-photos-source', {
                        'type': 'vector',
                        'tiles': [process.env.REACT_APP_WEBSVC_URL + '/tiles/user-photos/{z}/{x}/{y}/'],  // URL to your MVT endpoint
                        'minzoom': 0,
                        'maxzoom': 18,
                    });
                
                    this.map.current.addLayer({
                        'id': 'user-photos',  // A unique ID for this layer
                        'type': 'symbol',  // Change to 'symbol' for using icon images
                        'source': 'user-photos-source',
                        'source-layer': 'user_photos',  // This should match the layer name in your MVT
                        'layout': {
                            'icon-image': 'photo-marker',
                            'icon-size': 1,  // Adjust size as needed (values typically range between 0.5 and 2)
                            'icon-offset': [30, 30],
                        },
                        'paint': {
                            // Optional icon color styling if using SDF (make sure the image was added with { sdf: true })
                            'icon-color': '#FF0000'
                        }
                    });
    
                    this.map.current.on('mouseenter', ['user-photos'], this.onMouseEnterAll);
                    this.map.current.on('mouseleave', ['user-photos'], this.onMouseLeaveAll);

                    this.map.current.on('click', 'user-photos', this.onClick);

                }

                
            );


            this.map.current.loadImage(
                '/heart.png',
                (error, image) => {
                    if (error) throw error;
                    this.map.current.addImage('favorite-marker', image);
                    // Add a GeoJSON source with 2 points
                    
                    this.map.current.addSource('user-favorites-source', {
                        'type': 'vector',
                        'tiles': [process.env.REACT_APP_WEBSVC_URL + '/tiles/user-favorites/{z}/{x}/{y}/'],  // URL to your MVT endpoint
                        'minzoom': 0,
                        'maxzoom': 18,
                        'cluster': true,
                        'clusterRadius': 2,
                    });
                
                    this.map.current.addLayer({
                        'id': 'user-favorites',  // A unique ID for this layer
                        'type': 'symbol',  // Change to 'symbol' for using icon images
                        'source': 'user-favorites-source',
                        'source-layer': 'user_favorites',  // This should match the layer name in your MVT
                        'layout': {
                            'icon-image': 'favorite-marker',
                            'icon-size': 1,  // Adjust size as needed (values typically range between 0.5 and 2)
                            'icon-offset': [10, -15],
                        },
                        'paint': {
                            // Optional icon color styling if using SDF (make sure the image was added with { sdf: true })
                            'icon-color': '#FF0000'
                        }
                    });
    
                    this.map.current.on('mouseenter', ['user-favorites'], this.onMouseEnterAll);
                    this.map.current.on('mouseleave', ['user-favorites'], this.onMouseLeaveAll);

                    this.map.current.on('click', 'user-favorites', this.onClick);

                }

                
            );

            // tip layer
            this.map.current.loadImage(
                '/document.png',
                (error, image) => {
                    if (error) {
                        console.error("Error loading image:", error);
                        return;
                    }
                    
                    // Ensure the image is added with the correct name
                    this.map.current.addImage('review-marker', image);
        
                    // Add the GeoJSON source for user reviews
                    this.map.current.addSource('user-reviews-source', {
                        'type': 'vector',
                        'tiles': [process.env.REACT_APP_WEBSVC_URL + '/tiles/user-reviews/{z}/{x}/{y}/'],
                        'minzoom': 0,
                        'maxzoom': 18,
                    });
        
                    // Add the symbol layer with the loaded image
                    this.map.current.addLayer({
                        'id': 'user-reviews',
                        'type': 'symbol',
                        'source': 'user-reviews-source',
                        'source-layer': 'user_reviews',
                        'layout': {
                            'icon-image': 'review-marker',  // Match the name used in addImage
                            'icon-size': 1,  // Adjust size as needed
                            'icon-offset': [-10, 15],  // Offset the icon so it centers on the point

                        },
                    });

                    this.map.current.on('mouseenter', ['user-reviews'], this.onMouseEnterAll);
                    this.map.current.on('mouseleave', ['user-reviews'], this.onMouseLeaveAll);

                    this.map.current.on('click', 'user-reviews', this.onClick);
                }
            );
        });
        
    }
}

DashboardBaseMap.prototype.onClick = function(e) {
    console.log("CLICKED FAVORITE", e)
    console.log("e", e)
    const slug = e.features[0].properties.slug;
    
    console.log("slug", slug)
    this.props.navigation("/" + slug)
}


export default withRouter(DashboardBaseMap);