import React, { useState, useEffect } from 'react'
import http from './http-common'
import { useTheme } from '../contexts/theme-context'
import { CallToAction } from './call-to-action'
import activityNameToInt from './activityNameToInt'
import DeleteMe from './delete-record'

const DashboardComments = (props) => {
    const [comments, setComments] = useState([])
    const [pagination, setPagination] = useState({ next: null, previous: null });
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const {currentTheme} = useTheme()

    const activityInt = activityNameToInt(props.activity_type)

    const fetchFavorites = async (url) => {
        try {
            const endpoint = url || `/tips/?current_user_only=true&activity_type=${activityInt}`;
            const res = await http.get(endpoint)
            setComments(res.data.results)
            setPagination({ next: res.data.next, previous: res.data.previous })
            setIsLoading(false)
        } catch (error) {
            setIsError(true)
            setIsLoading(false)
        }
    }

    const onDelete = () => {

    }
    useEffect(() => {
        fetchFavorites()
    }, [])

    return (
        <>
            <div className={`border rounded-2xl p-4 mb-4 ${currentTheme.bg.card}`}>
                
                    <div className="capitalize">
                        <div className={"font-bold mb-2 text-xl"}>
                            {props.title}
                        </div>

                        {!isLoading && comments.length > 0 && (

                        <div className={"flex flex-wrap"}>
                            {comments.length > 0 && comments.map((comment) => (
                                <div key={comment.id} className="w-full pb-2 pr-2">

                                        <div className="card bg-base-100 shadow-xl">
                                            <div className="relative card-body">
                                                <button className={"w-full"} onClick={() => {window.location = `/${props.activity_type}/${comment?.slug}/`}}>
                                                    <h2 className="card-title">{comment?.name}</h2>
                                                    <p className="text-left">{comment?.text}</p>
                                                </button>
                                                    {/* <div
                                                        className={"absolute top-6 right-2 px-1 rounded-xl shadow-2xl hover:text-white text-black bg-white hover:bg-black hover:bg-opacity-50  bg-opacity-50 transform"}>
                                                        <DeleteMe name={"this tip"} url={`/tips/?id=${comment.id}`}
                                                                  onUpdate={onDelete}/>
                                                    </div> */}
                                            </div>
                                        </div>

                                </div>
                            ))}
                        </div>

                        )}

                        
                        <div className="pagination flex justify-between mt-2">
                            {pagination.previous && <button className={"btn btn-sm btn-outline bg-white"} onClick={() => fetchFavorites(pagination.previous)}>Previous</button>}
                            {pagination.next && <button className={'btn btn-sm btn-outline bg-white'} onClick={() => fetchFavorites(pagination.next)}>Next</button>}
                        </div>
                    </div>
                

                { !isLoading && comments.length === 0 && (
                    <CallToAction name={"Tips"} />
                ) }

            </div>
        </>
    )
}

export default DashboardComments;