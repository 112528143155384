import React, { useState } from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import http from "./http-common";
import TextAreaModalHTTPPost from "./modals/textarea-modal";

const EditTextComponent = ({ initialText, httpCode=200, url, callback=()=>{}, data={}, buttonName="" }) => {
    const [open, setOpen] = useState(false);
    const [editText, setEditText] = useState(initialText);

    const handleEditClick = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const handleSubmit = async (e) => {
        const postBody = {
            ...data
        };

        try {
            const rc = await http.put(`${url}`, postBody);

            if (rc.status === httpCode) {
                callback();
                setOpen(false);
            }
        } catch (error) {
            console.error('Failed to edit:', error);
        }
    };

    return (
        <div>
            <button
                onClick={handleEditClick}
                type="button"
                className="inline-flex items-center p-2 justify-center font-medium hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2"
            >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                <span>{buttonName}</span>
            </button>
            <TextAreaModalHTTPPost
                setIsVisible={setOpen}
                isVisible={open}
                title={"Edit Text"}
                textarea={editText}
                setTextArea={setEditText}
                submitAction={handleSubmit}
            />
        </div>
    );
};

export default EditTextComponent;