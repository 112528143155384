import React, { useState, useEffect } from 'react';
import {DashboardCounter} from './dashboard-counter';
import {SolidHeart} from './solid-heart';
import http from './http-common';
import CheckMarkStamp from "./check-mark-stamp";
import {useTheme} from "../contexts/theme-context";

export function FavoriteCounter(activityType) {


    const getURL = `/favorites/?current_user_only=true`;
    const queryKeyArray = ["favorites", "current_user"];
    const icon = <SolidHeart classes={"h-8 w-8"}/>;
    const name = "Favorites";

    return (
        <DashboardCounter
            icon={icon}
            getURL={getURL}
            queryKeyArray={queryKeyArray}
            name={name}
        />
    );
}