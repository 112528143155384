

export const ACTIVITY_CONSTANTS = {
    HIKING : 1,
    CAMPSITE : 2,
    CAMPGROUND : 3,
    CLIMBING : 4,
    ZIPCODE : 5,
    CITY : 6,
    PARK : 7
}

export const CONTRIBUTION_CONSTANTS = {
    FAVORITE : 2,
    BEEN_THERE : 4,
    PHOTOS : 3,
    TIP : 1
}