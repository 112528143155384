


const CheckMarkStamp = ({ color="#000", classes="w-6 h-6", fill=false }) => {

    return (
        <svg className={classes} fill={fill ? color : "none"}  fillRule={"nonzero"} stroke={color} strokeWidth="8" width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m35.5 86.5-31.898-31.898c-1.5-1.5-1.5-4 0-5.5l12.098-12.102c1.5-1.5 4-1.5 5.5 0l17.102 17.102 40.5-40.5c1.5-1.5 4-1.5 5.5 0l12.102 12.102c1.5 1.5 1.5 4 0 5.5l-55.402 55.297c-1.5 1.5-4 1.5-5.5 0z"/>
        </svg>
    )
}

export default CheckMarkStamp;