import {campsiteSVG, citySVG, climbingSVG, trailSVG, treeSVG} from "./maps/popup";
import {SolidHeart} from "./solid-heart";
import {CONTRIBUTION_CONSTANTS} from "../constants";
import CheckMarkStamp from "./check-mark-stamp";
import React from "react";
import {Camera} from "./camera";
import {CommentDocumentSVG} from "./CommentDocumentSVG";


export default function activityNameToInt(activityName) {

    if (activityName.includes("trail")) {
        return 1;
    }
    if (activityName.includes("campsite")) {
        return 2;
    }
    if (activityName.includes("campground")) {
        return 3;
    }
    if (activityName.includes("climbing")) {
        return 4;
    }

    console.log("activityNameToInt: activityName not found: ", activityName);
    return 0;
}

export function activityIntToName(activityInt) {

    activityInt = parseInt(activityInt);
    if (activityInt === 1) {
        return "trail";
    }
    if (activityInt === 2) {
        return "campsite";
    }
    if (activityInt === 3) {
        return "campground";
    }
    if (activityInt === 4) {
        return "climbing";
    }

    console.log("activityIntToName: activityInt not found: ", activityInt);
    return "";
}

export function activityIntToSVG(activityInt) {
    
    activityInt = parseInt(activityInt);
    if (activityInt === 1) {
        return trailSVG("w-6 h-6", "#000");
    }
    else if (activityInt === 2) {
        return campsiteSVG("h-4 w-4", "#000");
    }
    else if (activityInt === 4) {
        return climbingSVG("h-6 w-6", "#000");
    }
    else if (activityInt === 3) {
        return campsiteSVG("h-6 w-6", "#000");
    }
    else if (activityInt === 6) {
        return citySVG("h-4 w-4", "#000");
    }
    else if (activityInt === 5) {
        return citySVG("h-4 w-4", "#000");
    }
    else if (activityInt === 7) {
        return treeSVG("h-6 w-5", "#000");
    }
    else {
        console.log("activityINTToSVG: activityInt not found: ", activityInt);
        return "";
    }
}

export function contributionIntToSVG(contributionInt) {
    contributionInt = parseInt(contributionInt);
    if (contributionInt === CONTRIBUTION_CONSTANTS.FAVORITE) {
        return <SolidHeart classes={"h-4 w-4"}></SolidHeart>;
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.BEEN_THERE) {
        return <CheckMarkStamp classes={"h-4 w-4"} color={"green"} fill={true} />
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.PHOTOS) {
        return <Camera classes={"h-4 text-black"}/>;
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.TIP) {
        return <CommentDocumentSVG classes={"h-4 w-4 text-black"}/>;
    }
    else {
        console.log("contributionIntToSVG: contributionInt not found: ", contributionInt);
        return "";
    }
}

export function contributionIntToEndpoint(contributionInt) {
    contributionInt = parseInt(contributionInt);
    if (contributionInt === CONTRIBUTION_CONSTANTS.FAVORITE) {
        return "/favorites";
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.BEEN_THERE) {
        return "/been-there";
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.PHOTOS) {
        return "/photos";
    }
    else if (contributionInt === CONTRIBUTION_CONSTANTS.TIP) {
        return "/tips";
    }
    else {
        console.log("contributionIntToEndpoint: contributionInt not found: ", contributionInt);
        return "";
    }
}