import React, { useState, useEffect } from 'react';
import {DashboardCounter} from './dashboard-counter';
import {Camera} from './camera';
import http from './http-common';
import CheckMarkStamp from "./check-mark-stamp";


export function BeenThereCounter(activityType) {

    const getURL = `/been-there/?current_user_only=true`;
    const queryKeyArray = ["been-there", "current_user"];

    return (
        <>
            <DashboardCounter
                icon={<CheckMarkStamp classes={"h-8 w-8"} color={"green"} fill={true} />}
                getURL={getURL}
                queryKeyArray={queryKeyArray}
                name="Been There"
            />
        </>
    );
}