import React, {useContext, useEffect, useState} from "react";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {useTheme} from "../contexts/theme-context";
import http from "./http-common";
import {useQuery} from "@tanstack/react-query";

const Skeleton = () => {
    const {currentTheme} = useTheme()
    return (
        <div className={`${currentTheme.bg.skeleton} mx-auto w-8`}> </div>
    )
}

export function DashboardCounter({name, getURL, icon, queryKeyArray}) {
    const [count, setCount] = useState(0);

    const fetchData = async (key) => {
        const res = await http.get(getURL);
        let data  =  res.data

        if (data === undefined) {
            data = {}
        }

        if (data?.count) {
            setCount(data.count)
        }

        return data;
    }


    const { isLoading, data } = useQuery(queryKeyArray, fetchData)


    return (

        <div className={`flex text-center justify-center mt-2 `}>
            {/*<Tippy placement="bottom" content={`${name}`}>*/}
            <div className="w-24">
                {isLoading ? <Skeleton></Skeleton> : <div className="h-6 font-bold">{count}</div>}

                <div className="flex justify-center">
                    <div className="">{icon}</div>
                </div>
                <div>{name}</div>
            </div>
            {/*</Tippy>*/}
        </div>

    )
}