import React from 'react';
import { useTheme } from '../contexts/theme-context';
import ImageGetter from "../components/image-controls/image-getter";
import activityNameToInt from './activityNameToInt'; 
import { CallToAction } from './call-to-action';

export default function DashboardPhotos({activity_type}) {
    const {currentTheme} = useTheme()
    const activityTypeInt = activityNameToInt(activity_type)

    const imageGetter = <ImageGetter location_type={activity_type} current_user_only={true} activity_type={activityTypeInt} ></ImageGetter>

    return (
        <div className={`border rounded-2xl p-4 mb-4 ${currentTheme.bg.card}`}>
            <div className="font-bold mb-2 text-xl">
                Photos
            </div>
            <div className="flex items-center  justify-center mx-auto">
                <ImageGetter location_type={activity_type} show_delete_option={false} current_user_only={true} activity_type={activityTypeInt} ></ImageGetter>
            </div>
        </div>
    )
}
