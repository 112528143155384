import React, { useState, useEffect } from 'react';
import {DashboardCounter} from './dashboard-counter';
import {Camera} from './camera';
import http from './http-common';
import {pageType} from "./page-type";
import {useLocation} from "react-router-dom";
import activityNameToInt from "./activityNameToInt";
import {SolidHeart} from "./solid-heart";


export function PhotoCounter(activityType) {
    const getURL = `/photos/?current_user_only=true`;
    const queryKeyArray = ["photos", "current_user"];
    const icon = <Camera classes={"h-8"}/>;
    const name = "Photos";

    return (
        <DashboardCounter
            icon={icon}
            getURL={getURL}
            queryKeyArray={queryKeyArray}
            name={name}
        />
    );
}