import React, { useState } from 'react';
import http from "../components/http-common";
import { XIcon, TrashIcon } from '@heroicons/react/outline';

const DeleteComponent = ({ name="", url="", onUpdate=()=>{} }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDelete = async () => {
        try {
            await http.delete(url);

            // Callback to update the parent component
            try {
                onUpdate()
            } catch (error) {
                console.error('Failed to update parent:', error);
            }

            // Close the modal on success
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete:', error);
        }
    };

    const onClicked = (e) => {
        e.stopPropagation();
        setIsOpen(true)
    }

    return (
        <>
            <button onClick={onClicked } className="p-2 rounded">
                <TrashIcon className="h-5 w-5" />
            </button>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold">Confirm Deletion</h2>
                        <p className="my-2">Are you sure you want to delete {name}?</p>
                        <div className="flex justify-end gap-4">
                            <button onClick={() => setIsOpen(false)}
                                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">Cancel
                            </button>
                            <button onClick={handleDelete}
                                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DeleteComponent;
