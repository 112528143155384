import { useQueryClient, useQuery } from "@tanstack/react-query";
import http from "../http-common";
import React from 'react'
import left from './chevron-left.svg'
import right from './chevron-right.svg'
import LoadingSpinner from "../spinner/loading-spinner";
import DeleteMe from "../delete-record";


const ImageGetter = ({activity_type=null, location_type=null, activity_id=null, current_user_only=false, show_delete_option=false}) => {
    const [page, setPage] = React.useState(1)
    const queryClient = useQueryClient()

    function handlePage(pageNumber, totalPages) {

        if (pageNumber > totalPages)
            setPage(1)
        else if (pageNumber < 1)
            setPage(totalPages)
        else
            setPage(pageNumber)
    }

    const fetchAPI = async (page = 1) => {

        // we may have more than one query parameter or none at all so we need to build the URL dynamically, first get all the params
        let params = new URLSearchParams()
        if (activity_type) params.append('activity_type', activity_type)
        if (activity_id) params.append('activity_id', activity_id)
        if (current_user_only) params.append('current_user_only', current_user_only)
        if (page) params.append('page', page)

        // then build the URL
        let url = `/photos/?${params.toString()}`

        const res = await http.get(url);
        let data  =  res.data
        return data
        // return json;
    };

    const { isLoading, error, data, isPreviousData, isFetching } = useQuery({
        queryKey: ['Get-Images-Location', location_type, activity_id, page, current_user_only],
        queryFn: () => fetchAPI(page),
        keepPreviousData: true,
        staleTime: Infinity})

    // make a delete callback that will invalidate and refetch the query
    const onDelete = () => {
        console.log("deleting", activity_id)
        queryClient.invalidateQueries(['Get-Images-Location', location_type, activity_id, page, current_user_only]);
        queryClient.refetchQueries(['Get-Images-Location', location_type, activity_id, page, current_user_only])
        queryClient.invalidateQueries(["photos", "current_user"])
    }    

    
    return (
        data?.count > 0 ?

            <div className={"flex relative justify-center"}>

                {/* add image left/right controls and spinner */}
                {data?.count > 1 ? (
                <>
                    <button onClick={() => { handlePage(page - 1, data.count) }} className={"absolute top-1/2 left-2 bg-white bg-opacity-50 text-white p-2 rounded-full shadow-2xl transform -translate-y-1/2 hover:bg-opacity-50 hover:bg-black active:scale-95 active:bg-100"}>
                        <img className={"h-6 "} src={left}/>
                    </button>
                    { isFetching ? (<>
                            <div className={"absolute transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2"}>
                                <LoadingSpinner size={32}/>
                            </div>
                        </>) :
                        (null)
                    }
                    <button onClick={() => { handlePage(page + 1, data.count) }} className={"absolute top-1/2 right-2 bg-white bg-opacity-50 text-white p-2 rounded-full shadow-2xl transform -translate-y-1/2 hover:bg-opacity-50 hover:bg-black active:scale-95 active:bg-100"}>
                        <img className={"h-6"} src={right}/>
                    </button>
                    <div className={"absolute bottom-6 right-2 bg-black bg-opacity-50 text-xs text-white px-2 rounded-xl shadow-2xl transform"}>{page} of {data.count}</div>
                </>) : null}

                <img className={ "object-cover bg-opacity-50  items-center shadow-2xl mt-4 mb-4" } src={data?.results[0].image}/>
                { show_delete_option && (
                    <div className={"absolute top-6 right-2 px-1 rounded-xl shadow-2xl hover:text-white text-black bg-white hover:bg-black hover:bg-opacity-50  bg-opacity-50 transform"}>
                        <DeleteMe name={"this image"} url={`/photos/?id=${data?.results[0].id}`} onUpdate={onDelete}/>
                    </div> )
                }

            </div>
        : (null)
    )
}

export default ImageGetter