import React, {useEffect, useRef} from "react";
import {useState} from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import http from "../components/http-common";
import ServerSideTable from "../components/server-side-table";
import {FavoriteCounter} from "../components/favorite-counter";
import {BeenThereCounter} from "../components/been-there-counter";
import {PhotoCounter} from "../components/photo-counter";
import {CommentCounter} from "../components/comment-counter";
import {useTheme} from "../contexts/theme-context";
import {
    activityIntToName,
    activityIntToSVG,
    contributionIntToEndpoint,
    contributionIntToSVG
} from "../components/activityNameToInt";
import {SolidHeart} from "../components/solid-heart";
import MapThumbnail from "../components/map-thumbnail";
import {ACTIVITY_CONSTANTS, CONTRIBUTION_CONSTANTS} from "../constants";
import {Link} from "react-router-dom";
import DeleteComponent from "../components/delete-record";
import EditTextComponent from "../components/edit-text-component";
import {useQueryClient, useQuery} from "@tanstack/react-query";






const ResultsPage = () => {

    const queryClient = useQueryClient();

    const invalidateCallback = () => {  
        queryClient.invalidateQueries();
        queryClient.refetchQueries();
        queryClient.invalidateQueries();


    }

// Columns definition for the table
const columns = [

    {
        id: 6,
        header: '',
        accessorKey: 'thumbnail',
        cell: info => {
            const { thumbnail } = info.row.original;


            return <div className={"w-36"}><MapThumbnail url={thumbnail}></MapThumbnail></div>
        },
    },
        {
            id: 1,
            header: 'Name',
            accessorKey: 'name',
            cell: info => {
                const { name, slug, activity_type, contrib_type } = info.row.original;

                return (
                    <div className={"flex"}>
                        <div className={"my-auto"}>{contributionIntToSVG(contrib_type)}</div>
                        <Link to={`/${activityIntToName(activity_type)}/${slug}`}>
                        <div className={"ml-4"}>{name}</div>
                        </Link>
                    </div>
                );
            },
        },
    {
        id: 2,
        header: 'Activity',
        accessorKey: 'activity_type',
        cell: info => {
            const { activity_type } = info.row.original;
            return <div className={"flex"}><div>{activityIntToSVG(activity_type)}</div><div className={"capitalize my-auto"}>{activityIntToName(activity_type)}</div></div> ;
        }
    },

        {
            id: 7,
            header: 'Location',
            accessorKey: 'location'
        },


        {
            id: 4,
            header: '',
            cell: info => {
                const { id, activity_id, text, contrib_type, name } = info.row.original;
                return (
                    <div className={"flex w-full justify-end"}>
                    {contrib_type === CONTRIBUTION_CONSTANTS.TIP && (
                        <div className="flex my-auto">
                            <EditTextComponent initialText={text} callback={invalidateCallback} url={`${contributionIntToEndpoint(contrib_type)}/?activity_id=${activity_id}`}></EditTextComponent>
                        </div>
                    )}
                    {/*<button onClick={() => handleDelete(id)} className="text-red-500 flex justify-end w-full hover:underline">Delete</button>*/}
                        <div className="flex justify-end">
                            <DeleteComponent name={name} url={`${contributionIntToEndpoint(contrib_type)}/?activity_id=${activity_id}`} onUpdate={invalidateCallback}></DeleteComponent>
                        </div>
                    </div>
                );
            },
        },
    ]

    const {currentTheme} = useTheme()
    const [search, setSearch] = useState("");

    const [page, setPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(0)
    const [count, setCount] = React.useState(0)
    const inputRef = useRef(null);

    const [filters, setFilters] = useState({
        activity_type: [],
        contrib_type: [],
    });

    const [checkedStates, setCheckedStates] = useState({
        favorite: false,
        beenThere: false,
        photos: false,
        tip: false,
        trails: false,
        campsites: false,
        climbing: false,
    });

    // Build query params
    const buildQueryParams = () => {
        const params = new URLSearchParams();

        Object.entries(filters).forEach(([key, values]) => {
            values.forEach((value) => params.append(key, value));
        });

        if (page > 1) {
            // add page number to the query params
            params.append('page', page);
        }

        if (search) params.append("search", search);

        return `?${params.toString()}`;
    };

    const [queryParams, setQueryParams] = useState("");



    const toggleCheckedState = (key) => {
        setCheckedStates((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };



    // Toggle a filter
    const toggleFilter = (category, value) => {
        setFilters((prevFilters) => {
            const currentValues = prevFilters[category] || [];
            const isActive = currentValues.includes(value);

            // Add or remove the value based on current state
            const updatedValues = isActive
                ? currentValues.filter((v) => v !== value)
                : [...currentValues, value];

            return {
                ...prevFilters,
                [category]: updatedValues,
            };
        });

        setPage(1);
    };



    // useEffect(() => {
    //     const queryParams = buildQueryParams();
    //     setQueryParams(queryParams);
    // }
    // , [filters, page, search])


    const fetchData = async (key) => {
        console.log("queryParams", queryParams)
        const res = await http.get(`/contributions/${buildQueryParams()}`);
        let data  =  res.data

        if (data === undefined) {
            data = {}
        }

        if (res.status === 200) {
            setCount(data.count)
            setPageSize(data.page_size)
        }

        return data;
    }


    const { isLoading, data } = useQuery(["contributions", filters, page], fetchData, { keepPreviousData: true })


    const flipPage = (page) => {

        if (page < 1) {
            page = 1
        }
        if (page > Math.ceil(count/pageSize)) {
            page = Math.ceil(count/pageSize)
        }

        setPage(page)
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const showingOf = () => {
        if (count === 0) {
            return "No Results Found"
        }
        return `Showing ${((page - 1) * pageSize + 1)}-${Math.min(page * pageSize, count)} of ${count}`
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <div className={"flex-1"}>
                <Nav></Nav>
                <div
                    className="mx-auto md:max-w-xl transform divide-y divide-gray-100 rounded-xl bg-white ring-1 ring-black ring-opacity-5 transition-all mt-4">
                    <div className="relative">
                        <svg className="pointer-events-none absolute left-2 top-3 h-6 w-8 text-gray-400"
                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd"
                                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                  clipRule="evenodd"/>
                        </svg>
                        <input id={"results-search"} type="text"
                               value={search}
                               onChange={handleSearchChange}
                               autoComplete="off"
                               ref={inputRef}
                               placeholder={"Search locations..."}
                               className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-xl"
                               role="combobox" aria-expanded="false" aria-controls="options"/>
                    </div>
                </div>
                <div className={"flex rounded-xl border-2 justify-around mt-4 ml-8 mr-8"}>
                    <button 
                        className="flex-grow"
                        onClick={() => {
                        toggleCheckedState('trails');
                        toggleFilter("activity_type", ACTIVITY_CONSTANTS.HIKING);
                    }}>
                        <div className={`flex rounded-xl justify-center p-2 w-full ${checkedStates.trails ? 'bg-gray-200' : ''}`}>Trails</div>
                    </button>
                    <button 
                        className="flex-grow justify-center"
                        onClick={() => {
                        toggleCheckedState('campsites');
                        toggleFilter("activity_type", ACTIVITY_CONSTANTS.CAMPSITE);
                        toggleFilter("activity_type", ACTIVITY_CONSTANTS.CAMPGROUND);
                    }}>
                        <div className={`flex rounded-xl justify-center p-2 w-full ${checkedStates.campsites ? 'bg-gray-200' : ''}`}>Campsites</div>
                    </button>
                    <button 
                        className="flex-grow"
                        onClick={() => {
                        toggleCheckedState('climbing');
                        toggleFilter("activity_type", ACTIVITY_CONSTANTS.CLIMBING);
                    }}>
                        <div className={`flex rounded-xl justify-center p-2 w-full ${checkedStates.climbing ? 'bg-gray-200' : ''}`}>Climbing</div>
                    </button>
                </div>
                <div className={` flex flex-wrap justify-around mt-4 pb-4`}>
                    <button className={`p-1 rounded-xl border border-2 transform hover:scale-105 ${checkedStates.favorite ? 'bg-gray-200' : ''}`}
                            onClick={() => {
                                toggleCheckedState('favorite');
                                toggleFilter("contrib_type", CONTRIBUTION_CONSTANTS.FAVORITE);
                            }}
                    >
                        <FavoriteCounter></FavoriteCounter>
                    </button>
                    <button className={`p-1 rounded-xl border border-2 transform hover:scale-105 ${checkedStates.beenThere ? 'bg-gray-200' : ''}`}
                            onClick={() => {
                                toggleCheckedState('beenThere');
                                toggleFilter("contrib_type", CONTRIBUTION_CONSTANTS.BEEN_THERE)
                            }}
                    >
                        <BeenThereCounter></BeenThereCounter>
                    </button>
                    <button className={`p-1 rounded-xl border border-2 transform hover:scale-105 ${checkedStates.photos ? 'bg-gray-200' : ''}`}
                            onClick={() => {
                                toggleCheckedState('photos');
                                toggleFilter("contrib_type", CONTRIBUTION_CONSTANTS.PHOTOS)
                            }}
                    >
                        <PhotoCounter></PhotoCounter>
                    </button>
                    <button className={`p-1 rounded-xl border border-2 transform hover:scale-105 ${checkedStates.tip ? 'bg-gray-200' : ''}`}
                            onClick={() => {
                                toggleCheckedState('tip');
                                toggleFilter("contrib_type", CONTRIBUTION_CONSTANTS.TIP)
                            }}>
                        <CommentCounter></CommentCounter>
                    </button>
                </div>
                <div className={"flex w-full justify-between items-center"}>
                    <button disabled={page === 1} onClick={() => {
                        flipPage(page - 1)
                    }} className={`rounded ml-8 w-32 ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        <div
                            className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <svg className="w-3.5 h-3.5 me-2 mr-2 rtl:rotate-180" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                            </svg>
                            Previous
                        </div>
                    </button>
                    <div className="flex-grow text-center">
                        { showingOf() }
                    </div>
                    <button
                        disabled={page * pageSize >= count}
                        onClick={() => {
                            flipPage(page + 1)
                        }}
                        className={`rounded mr-8 w-32 ${page * pageSize >= count ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        <div
                            className="flex items-center justify-center px-3 h-8 text-sm font-medium bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                            <svg className="w-3.5 h-3.5 ms-2 ml-2 rtl:rotate-180" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </div>
                    </button>
                </div>
                <div className="flex">


                </div>
                <div className={"ml-8 mr-8 mt-4"}>

                    { isLoading ? <div>Loading...</div> : <ServerSideTable columns={columns} data={data.results} hideHeader={true}></ServerSideTable> }
                    
                </div>
            </div>
            <Footer backgroundColor={"bg-[#071b1f]"}></Footer>
        </div>
    )
}

export default ResultsPage;